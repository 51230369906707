import React, { useState, useEffect, useCallback } from "react";

import Iicon from "../../assects/images/i.png";
import Loinp from '../../assects/images/dcoin.png'
import eth from "../../assects/images/loinppre (1).png";
import USDT from "../../assects/images/USDT.png";
import BNB from "../../assects/images/loinppre (2).png";
import Button from '../../components/Button/button';
import downarw from "../../assects/svgs/droparw.svg"
import downeth from "../../assects/svgs/dropeth.svg"
import Solid from "../../assects/images/logo-white-3.png.png"
import poster from "../../assects/images/vedio.png"
import que from "../../assects/svgs/questionIcon.svg"
import refe from "../../assects/svgs/referralIcon.svg"
import imgclose from "../../assects/images/close.png"

import '@rainbow-me/rainbowkit/styles.css';

import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';

import { bsc } from 'wagmi/chains'
import { useWriteContract, useAccount, useBalance } from 'wagmi'
import { useReadContract, useWaitForTransactionReceipt } from 'wagmi'
import { useSwitchChain } from 'wagmi'
import {
    useConnectModal,
    useChainModal,
} from '@rainbow-me/rainbowkit';

import { ConnectButton } from '@rainbow-me/rainbowkit';

import { parseEther } from 'viem'

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    XIcon,
    TelegramShareButton,
    TelegramIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from "react-share";

import { useTranslation } from 'react-i18next';
import { getTotalContribution, getTotalContributionETH, getTotalParticipants, getTotalParticipantsETH } from "./tokendata";


const stakeAndClaimContract = "0x255b82eC452E00e3E9D2c56441AB133B616704F8";
const dreamcarsAddress = "0xa910A46E2F2002Fa9B5aA85F35b9440F6DAC4b10";

const abi = [
    {
        name: "getAmountsOut",
        type: "function",
        inputs: [
            {
                name: "amountIn",
                type: "uint256",
            },
            { name: "path", type: "address[]" },
        ],
        outputs: [{ name: "amounts", type: "uint256[]" }],
    },
];


const tokenAbi = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];


const ProductDetailsSection = () => {
    const [pop2, setPop2] = useState(false);
    const [show, setShow] = useState(false);

    const { chains, switchChain } = useSwitchChain()
    const { isConnected } = useAccount()
    const [currentChain, setCurrentChain] = useState('BSC');
    const [tokenChoice, setTokenChoice] = useState('USDT');
    const [referral, setReferral] = useState('0x0000000000000000000000000000000000000000');
    const [ethPrice, setEthPrice] = useState(1);
    const [bnbPrice, setBNBPrice] = useState(1);
    const [currentDcarsPrice, setCurrentScorpPrice] = useState(100);
    const [percentage, setPercentage] = useState(0);
    const [currentAllowance, setCurrentAllowance] = useState(0);
    const [currentAllowanceETH, setCurrentAllowanceETH] = useState(0);
    const [totalContributions, setTotalContributions] = useState(0);
    const [totalParticipants, setTotalParticipants] = useState(0);
    const [userBalanceBNB, setUserBalanceBNB] = useState(0);
    const [loadingStatus, setLoadingStatus] = useState(false);

    const { openConnectModal } = useConnectModal();
    const { openChainModal } = useChainModal();
    const { t, i18n } = useTranslation();
    const [pop1, setPop1] = useState(false);
    const [userAmount, setUserAmount] = useState(1000);
    const [userUSD, setUserUSD] = useState(100000);
    const [userAddress, setUserAddress] = useState(0);
    const [hasBonus, setHasBonus] = useState(false);
    const [txHash, setTxHash] = useState("");
    const [txHashETH, setTxHashETH] = useState("");
    const [buynowbtn, setBuyNowBtn] = useState(t("BUY $DCARS NOW"));
    const [typeOfTx, setTypeOfTx] = useState("Authorize");

    const [coupon, setCoupon] = useState("0");

    const { data: totalParticipantETH, refetch: refreshParticipantsETH } = useReadContract({
        address: '0x076b20436b7cf3d461b5b2260a9eeee1967b1dba',
        abi: [{
            "inputs": [],
            "name": "getTotalParticipants",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getTotalParticipants',
        chainId: 1
    })
    const { data: totalContributionETH, refetch: refreshContributorsETH, isFetched: isContriError } = useReadContract({
        address: '0x076b20436b7cf3d461b5b2260a9eeee1967b1dba',
        abi: [{
            "inputs": [],
            "name": "getTotalContribution",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getTotalContribution',
        chainId: 1
    })
    const { data: totalParticipant, refetch: refreshParticipants } = useReadContract({
        address: '0x883178a1571094a6656cca084f3ed5a3192abaf3',
        abi: [{
            "inputs": [],
            "name": "getTotalParticipants",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getTotalParticipants',
        chainId: 56
    })
    const { data: totalContribution, refetch: refreshContributors } = useReadContract({
        address: '0x883178a1571094a6656cca084f3ed5a3192abaf3',
        abi: [{
            "inputs": [],
            "name": "getTotalContribution",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getTotalContribution',
        chainId: 56
    })
    const { data: allowance, isSuccess: successAllowance, refetch: allowanceRefetch } = useReadContract({
        address: '0x55d398326f99059fF775485246999027B3197955',
        abi: [{
            "constant": true,
            "inputs": [
                {
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "spender",
                    "type": "address"
                }
            ],
            "name": "allowance",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'allowance',
        chainId: 56,
        args: [userAddress, '0x883178a1571094a6656cca084f3ed5a3192abaf3'],
    })
    const { data: allowanceETH, isSuccess: successAllowanceETH, refetch: allowanceETHRefetch } = useReadContract({
        address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        abi: [{
            "constant": true,
            "inputs": [
                {
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "spender",
                    "type": "address"
                }
            ],
            "name": "allowance",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'allowance',
        chainId: 1,
        args: [userAddress, '0x076b20436b7cf3d461b5b2260a9eeee1967b1dba'],
    })

    const { data: bnbData, writeContract: buyUsingBNB, error: bnbErrors, isError: bnbError, isSuccess: bnbSuccess } = useWriteContract()
    const { data: usdtData, writeContract: buyUsingUSDT, isError: usdtError, isSuccess: usdtSuccess } = useWriteContract()
    const { data: allowanceData, writeContract: allowanceUSDT, error: allowanceUSDTerror, isSuccess: allowanceSuccess } = useWriteContract()

    const { data: ethData, writeContract: buyUsingETH, isError: ETHError, isSuccess: ETHSuccess } = useWriteContract()
    const { data: usdtethData, writeContract: buyUsingUSDTETH, isError: usdtEthError, isSuccess: usdtETHSuccess } = useWriteContract()
    const { data: allowanceETHData, writeContract: allowanceUSDTETH, isError: allowanceUSDTETHError, isSuccess: allowanceSuccessETH } = useWriteContract()

    const { data: bnbBalance, status } = useBalance({
        address: userAddress,
        chainId: 56
    })
    const { data: ethBalance, status: ethStatus } = useBalance({
        address: userAddress,
        chainId: 1
    })
    const couponCodes = ["scorp20", "scorpion20", "launch20", "dtc20", "sheriff20", "futuro20", , "atamert20", "odysfox20", "sc20", "jumanji20", "biznes20", "hot20", "ande20", "clinix20", "aless20", "panda20", "bri20", "wizard20", "matrix20", "phoenix20"]
    const handleCoupon = (e) => {
        e.preventDefault();

        var arraycontainsturtles = (couponCodes.indexOf(coupon.toLowerCase()) > -1);

        if (arraycontainsturtles) {
            toast.success("The bonus is applied!", {
                position: "bottom-right"
            })
        }
        else
            toast.error("Code not valid.", {
                position: "bottom-right"
            })
        // for (let d = 0; d < couponCodes.length; d++) {
        //     if (coupon.toLowerCase() === couponCodes[d]) {
        //         toast.success("The bonus is applied!", {
        //             position: "bottom-right"
        //         })
        //     }
        //     else
        //         toast.error("Code not valid.", {
        //             position: "bottom-right"
        //         })
        // }
    }
    function getURLParams() {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);

        const result = {};
        for (const [key, value] of params.entries()) {
            result[key] = value;
        }

        return result;
    }
    // useEffect(() => {
    //     handleRange(userUSD / currentDcarsPrice)
    // }, [userUSD])
    // useEffect(() => {
    //     handleRange(1000)
    // }, [])
    const handleUserInput = async (e) => {
        let correctedInput = e.target.value.replace(/,/g, '.');
        if (!isNaN(correctedInput)) {
            if (correctedInput) {
                setUserAmount(correctedInput)
                if (tokenChoice === "ETH") {
                    setUserUSD(correctedInput * await getEthPrice() * currentDcarsPrice)
                    setBuyNowBtn(t("BUY $DCARS NOW"))
                }
                else if (tokenChoice === "BNB") {
                    setUserUSD(correctedInput * await getBNBPrice() * currentDcarsPrice)
                    setBuyNowBtn(t("BUY $DCARS NOW"))
                } else {
                    if (currentAllowance + currentAllowanceETH < correctedInput * 1e18)
                        setBuyNowBtn(t("Approve transaction"))
                    else
                        setBuyNowBtn(t("BUY $DCARS NOW"))
                    setUserUSD(correctedInput * currentDcarsPrice)
                }
            }
            else {
                setUserAmount(0)
                setUserUSD(0)
            }
        }
    }

    const handleDcarsInput = async (e) => {
        let correctedInput = e.target.value.replace(/,/g, '.');

        if (!isNaN(correctedInput)) {
            if (correctedInput) {
                setUserUSD(correctedInput)
                if (tokenChoice === "ETH") {
                    setUserAmount(((correctedInput / await getEthPrice()) / currentDcarsPrice).toFixed(8))
                }
                else if (tokenChoice === "BNB") {
                    setUserAmount(((correctedInput / await getBNBPrice()) / currentDcarsPrice).toFixed(8))
                } else {
                    setUserAmount(correctedInput / currentDcarsPrice)
                }
            }
            else {
                setUserAmount(0)
                setUserUSD(0)
            }
        }
    }


    const buyToken = async () => {
        if (userAmount > 0) {
            if (tokenChoice === 'USDT') {
                console.log(currentChain)
                if (currentChain === "Ethereum") {
                    let aer = await allowanceETHRefetch()
                    setCurrentAllowanceETH(Number(aer?.data))

                    if (Number(aer?.data) === 0) {
                        setBuyNowBtn(t("Approve Transaction"))
                        setTypeOfTx("Authorize for USDT")
                        setLoadingStatus(true)
                        allowanceUSDTETH({
                            address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
                            abi: [{ "constant": false, "inputs": [{ "name": "_spender", "type": "address" }, { "name": "_value", "type": "uint256" }], "name": "approve", "outputs": [], "payable": false, "stateMutability": "nonpayable", "type": "function" }],
                            functionName: 'approve',
                            chainId: 1,
                            args: ["0x076b20436b7cf3d461b5b2260a9eeee1967b1dba", userAmount * 1e6],
                            gas: 300000
                        })
                    }
                    else {
                        if (userAmount * 1e6 != Number(aer?.data))
                            toast.info("You have initially approved: " + Number(aer?.data) / 1e6 + " USDT, please send it first.")
                        else {
                            setLoadingStatus(true)
                            toast.error("Confirm the transaction")
                            buyUsingUSDTETH({
                                address: '0x076b20436b7cf3d461b5b2260a9eeee1967b1dba',
                                abi: [{
                                    "inputs": [
                                        {
                                            "internalType": "uint256",
                                            "name": "amountInUSD",
                                            "type": "uint256"
                                        },
                                        {
                                            "internalType": "string",
                                            "name": "code",
                                            "type": "string"
                                        },
                                        {
                                            "internalType": "address",
                                            "name": "_referredBy",
                                            "type": "address"
                                        }
                                    ],
                                    "name": "contribute",
                                    "outputs": [],
                                    "stateMutability": "payable",
                                    "type": "function"
                                }],
                                functionName: 'contribute',
                                chainId: 1,
                                args: [userAmount * 1e6, coupon, referral],
                            })
                            setTypeOfTx("Confirm the transaction")
                            setBuyNowBtn(t("BUY $DCARS NOW"))
                        }
                    }
                }
                else if (currentChain === "BSC") {
                    let ar = await allowanceRefetch()
                    setCurrentAllowance(Number(ar?.data))
                    console.log("test")
                    toast.info("Confirm the transaction")
                    if (Number(ar?.data) < userAmount * 1e18) {
                        setLoadingStatus(true)
                        allowanceUSDT({
                            address: '0x55d398326f99059fF775485246999027B3197955',
                            abi: [{
                                "constant": false,
                                "inputs": [
                                    {
                                        "internalType": "address",
                                        "name": "spender",
                                        "type": "address"
                                    },
                                    {
                                        "internalType": "uint256",
                                        "name": "amount",
                                        "type": "uint256"
                                    }
                                ],
                                "name": "approve",
                                "outputs": [
                                    {
                                        "internalType": "bool",
                                        "name": "",
                                        "type": "bool"
                                    }
                                ],
                                "payable": false,
                                "stateMutability": "nonpayable",
                                "type": "function"
                            }],
                            functionName: 'approve',
                            chainId: bsc.id,
                            gasPrice: 3000000000,
                            gas: 200000,

                            args: ["0x883178a1571094a6656cca084f3ed5a3192abaf3", parseEther(userAmount.toString())],
                        })
                        setTypeOfTx("Authorize for USDT")
                        setBuyNowBtn(t("Approve Transaction"))
                    }
                    else {
                        setLoadingStatus(true)
                        toast.error("Confirm the transaction")
                        buyUsingUSDT({
                            address: '0x883178a1571094a6656cca084f3ed5a3192abaf3',
                            abi: [{
                                "inputs": [
                                    {
                                        "internalType": "uint256",
                                        "name": "amountInUSD",
                                        "type": "uint256"
                                    },
                                    {
                                        "internalType": "string",
                                        "name": "code",
                                        "type": "string"
                                    },
                                    {
                                        "internalType": "address",
                                        "name": "_referredBy",
                                        "type": "address"
                                    }
                                ],
                                "name": "contribute",
                                "outputs": [],
                                "stateMutability": "payable",
                                "type": "function"
                            }],
                            functionName: 'contribute',
                            chainId: bsc.id,
                            gasPrice: 3000000000,
                            gas: 300000,
                            args: [parseEther(userAmount.toString()), coupon, referral],
                        })
                        setTypeOfTx("Confirm the transaction")
                        setBuyNowBtn(t("BUY $DCARS NOW"))
                    }
                }
            }
            else if (tokenChoice === 'BNB') {
                console.log("touched")
                let bnbBal = bnbBalance
                try {

                    if (Number(bnbBal?.formatted) > Number(userAmount)) {
                        setLoadingStatus(true)
                        setTypeOfTx("Confirm the transaction")
                        toast.error("Confirm the transaction")
                        buyUsingBNB({
                            address: '0x883178a1571094a6656cca084f3ed5a3192abaf3',
                            abi: [{
                                "inputs": [
                                    {
                                        "internalType": "uint256",
                                        "name": "amountInUSD",
                                        "type": "uint256"
                                    },
                                    {
                                        "internalType": "string",
                                        "name": "code",
                                        "type": "string"
                                    },
                                    {
                                        "internalType": "address",
                                        "name": "_referredBy",
                                        "type": "address"
                                    }
                                ],
                                "name": "contribute",
                                "outputs": [],
                                "stateMutability": "payable",
                                "type": "function"
                            }],
                            functionName: 'contribute',
                            chainId: bsc.id,
                            args: [0, coupon, referral],
                            value: (parseEther(userAmount.toString())),
                            gasPrice: 3000000000,
                            gas: 350000

                        })
                    }
                    else
                        toast.error("Please check if you have enough funds and try again.")

                } catch (error) {
                    console.log(error)
                }
            }
            else if (tokenChoice === 'ETH') {
                let ethBal = ethBalance
                if (Number(ethBal?.formatted) > Number(userAmount)) {
                    setLoadingStatus(true)
                    setTypeOfTx("Confirm the transaction")
                    toast.error("Confirm the transaction")
                    buyUsingETH({
                        address: '0x076b20436b7cf3d461b5b2260a9eeee1967b1dba',
                        abi: [{
                            "inputs": [
                                {
                                    "internalType": "uint256",
                                    "name": "amountInUSD",
                                    "type": "uint256"
                                },
                                {
                                    "internalType": "string",
                                    "name": "code",
                                    "type": "string"
                                },
                                {
                                    "internalType": "address",
                                    "name": "_referredBy",
                                    "type": "address"
                                }
                            ],
                            "name": "contribute",
                            "outputs": [],
                            "stateMutability": "payable",
                            "type": "function"
                        }],
                        functionName: 'contribute',
                        chainId: 1,
                        args: [0, coupon, referral],

                        value: parseEther(userAmount.toString()),
                        gas: 300000
                    })
                }
                else
                    toast.error("Please check if you have enough funds and try again.")
            }
        }
    }

    const handleTokenChange = async (token) => {
        if (isConnected) {
            if (token === 1) {
                if (currentChain !== 'Ethereum') {
                    switchChain({ chainId: 1 })
                } else {
                    setTokenChoice('ETH')
                    setBuyNowBtn(t("BUY $DCARS NOW"))
                    if (Number(ethBalance?.formatted).toFixed(6) - 0.005 > 0) {
                        setUserAmount(Number(ethBalance?.formatted).toFixed(6) - 0.005)
                        setUserUSD(Number(ethBalance?.formatted).toFixed(6) * await getEthPrice() * currentDcarsPrice)
                    }
                    else {
                        setUserAmount(1)
                        setUserUSD(await getEthPrice() * currentDcarsPrice)
                    }

                }
            }
            else if (token === 2) {
                setTokenChoice('USDT')
                if (currentAllowance + currentAllowanceETH < userAmount * 1e18)
                    setBuyNowBtn(t("Approve transaction"))
                openChainModal?.();
                // setUserUSD(userAmount * currentDcarsPrice)

                setUserAmount(1000)
                setUserUSD(1000 * currentDcarsPrice)

            }
            else {
                if (currentChain !== 'BSC') {
                    switchChain({ chainId: 56 })
                } else {
                    setTokenChoice('BNB')
                    setBuyNowBtn(t("BUY $DCARS NOW"))
                    if (Number(bnbBalance?.formatted).toFixed(6) - 0.0015 > 0) {
                        setUserAmount(Number(bnbBalance?.formatted).toFixed(6) - 0.0015)
                        setUserUSD(Number(bnbBalance?.formatted).toFixed(6) * await getBNBPrice() * currentDcarsPrice)
                    }
                    else {
                        setUserAmount(1)
                        setUserUSD(await getBNBPrice() * currentDcarsPrice)
                    }
                }
            }
        }
        else
            openConnectModal?.();
    }

    const { data } = useWaitForTransactionReceipt({
        confirmations: 3,
        hash: txHash,
    })

    const { data: dataETH } = useWaitForTransactionReceipt({
        confirmations: 3,
        hash: txHashETH,
    })

    useEffect(() => {
        (async () => {
            if (data) {
                toast.success("Transaction was successful 🎉");
                let con = await refreshContributors();
                let conETH = await refreshContributorsETH();
                let par = await refreshParticipants();
                let parETH = await refreshParticipantsETH();
                let x = Number(con?.data) / 1000000
                let y = Number(conETH?.data) / 1000000
                setTotalContributions(x + y + 157454)
                if (x + y < 0)
                    setPercentage(((x + y + 157454) / 200000 * 100))
                else
                    setPercentage(((x + y + 157454) / 200000 * 100))
                setTotalParticipants(Number(par?.data) + Number(parETH?.data) + 279)
                setLoadingStatus(false)
            }
        }
        )()
    }, [data])

    useEffect(() => {
        (async () => {
            if (dataETH) {
                toast.success("Approval was successful 🎉");
                setLoadingStatus(false)
                setBuyNowBtn(t("BUY $DCARS NOW"))
            }
        }
        )()
    }, [dataETH])
    useEffect(() => {
        if (allowanceETHData)
            setTxHashETH(allowanceETHData?.hash)
        if (allowanceData)
            setTxHashETH(allowanceData?.hash)
    }, [allowanceETHData, allowanceData])
    useEffect(() => {
        if (bnbData)
            setTxHash(bnbData?.hash)
        if (ethData)
            setTxHash(ethData?.hash)
        if (usdtData)
            setTxHash(usdtData?.hash)
        if (usdtethData)
            setTxHash(usdtethData?.hash)
    }, [bnbData, usdtethData, usdtData, ethData])

    useEffect(() => {
        if (bnbSuccess)
            setTypeOfTx("Waiting for confirmation...")
        if (ETHSuccess)
            setTypeOfTx("Waiting for confirmation...")
        if (usdtETHSuccess)
            setTypeOfTx("Waiting for confirmation...")
        if (usdtSuccess)
            setTypeOfTx("Waiting for confirmation...")
        if (allowanceSuccessETH)
            setTypeOfTx("Waiting for confirmation...")
        if (allowanceSuccess)
            setTypeOfTx("Waiting for confirmation...")
    }, [bnbSuccess, ETHSuccess, usdtETHSuccess, usdtSuccess, allowanceSuccessETH, allowanceSuccess])
    useEffect(() => {
        let x = Number(totalContribution) / 1000000
        let y = Number(totalContributionETH) / 1000000
        setTotalContributions(x + y + 157454)
        console.log(isContriError)
        console.log(totalContribution)
        console.log(totalContributionETH)
        if (x + y < 0)
            setPercentage(((x + y + 157454) / 200000 * 100))
        else
            setPercentage(((x + y + 157454) / 200000 * 100))
        setTotalParticipants(Number(totalParticipant) + Number(totalParticipantETH) + 279)
    }, [isContriError])

    useEffect(() => {
        if (totalParticipants === 0 && totalContributions === 0) {
            let x = Number(totalContribution) / 1000000
            let y = Number(totalContributionETH) / 1000000
            setTotalContributions(x + y + 157454)
            if (x + y < 0)
                setPercentage(((x + y + 157454) / 200000 * 100))
            else
                setPercentage(((x + y + 157454) / 200000 * 100))
            setTotalParticipants(Number(totalParticipant) + Number(totalParticipantETH) + 279)
        }
    }, [totalContributions, totalParticipants])

    useEffect(() => {
        if (bnbError) {
            setLoadingStatus(false)
        }
        if (ETHError)
            setLoadingStatus(false)
        if (usdtError) {
            setLoadingStatus(false)
        }
        if (usdtEthError)
            setLoadingStatus(false)
        if (allowanceUSDTETHError)
            setLoadingStatus(false)
        if (allowanceUSDTerror) {
            setLoadingStatus(false)
        }
    }, [bnbError, ETHError, usdtError, usdtEthError, allowanceUSDTETHError, allowanceUSDTerror])

    useEffect(() => {
        if (allowanceSuccess) {
            setBuyNowBtn(t("BUY $DCARS NOW"))
        }
    }, [allowanceSuccess])
    useEffect(() => {
        if (allowanceSuccessETH)
            setBuyNowBtn(t("BUY $DCARS NOW"))
    }, [allowanceSuccessETH])
    useEffect(() => {
        const xallowance = Number(allowance);
        const yallowance = Number(allowanceETH)
        setCurrentAllowance(xallowance)
        setCurrentAllowanceETH(yallowance)

    }, [successAllowance, successAllowanceETH])


    useEffect(() => {
        (async () => {
            if (chains) {
                let ref = getURLParams().ref
                if (ref === undefined)
                    ref = "0x0000000000000000000000000000000000000000"
                else
                    setCoupon("ref")
                setReferral(ref)
                if (chains.name === 'Ethereum') {
                    setTokenChoice('ETH')
                    setBuyNowBtn(t("BUY $DCARS NOW"))

                    if (ethStatus === "success") {
                        if (ethBalance.formatted !== "0") {
                            setUserAmount(Number(ethBalance?.formatted).toFixed(6) - 0.005)
                            setUserUSD(Number(ethBalance?.formatted).toFixed(6) * await getEthPrice() * currentDcarsPrice)
                        }
                        else {
                            setUserAmount(0)
                            setUserUSD(0)
                        }
                    }
                }
                else if (chains.name === 'BNB Smart Chain') {
                    setTokenChoice('BNB')
                    setBuyNowBtn(t("BUY $DCARS NOW"))
                    if (status === "success") {
                        if (bnbBalance?.formatted !== "0") {
                            setUserAmount(Number(bnbBalance?.formatted).toFixed(6) - 0.0015)
                            setUserUSD(Number(bnbBalance?.formatted).toFixed(6) * await getBNBPrice() * currentDcarsPrice)
                        } else {
                            setUserAmount(0)
                            setUserUSD(0)
                        }
                    }
                }
            }
        }
        )()
    }, [])

    const getEthPrice = useCallback(async () => {
        let a = 0;
        await axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD')

            .then(function (response) {
                // handle success
                setEthPrice(Number(response.data.USD))
                // await axios.get('https://min-api.crypt
                a = response.data.USD
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
            });
        return Number(a);
    }, [ethPrice])


    const getBNBPrice = useCallback(async () => {
        let a = 0;
        // await axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD')
        await axios.get('https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD')

            .then(function (response) {
                // handle success
                setBNBPrice(Number(response.data.USD));
                a = response.data.USD
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
            });
        return Number(a);
    }, [bnbPrice])


    useEffect(() => {
        (async () => {
            if (chains?.name === 'Ethereum') {

                if (ethStatus === "success") {
                    if (ethBalance.formatted !== "0") {
                        setUserAmount(Number(ethBalance?.formatted).toFixed(6) - 0.005)
                        setUserUSD(Number(ethBalance?.formatted).toFixed(6) * await getEthPrice() * currentDcarsPrice)

                    }
                    else {
                        setUserAmount(0)
                        setUserUSD(0)
                    }
                }
            }
            else if (chains?.name === 'BNB Smart Chain') {

                if (status === "success") {
                    if (bnbBalance?.formatted !== "0") {
                        setUserAmount(Number(bnbBalance?.formatted).toFixed(6) - 0.0015)
                        setUserUSD(Number(bnbBalance?.formatted).toFixed(6) * await getBNBPrice() * currentDcarsPrice)
                    } else {
                        setUserAmount(0)
                        setUserUSD(0)
                    }
                }
            }
        }
        )()
    }, [status, ethStatus])

    const [refPop, setRefPop] = useState(false);

    const handleRef = () => {
        setRefPop(true)
    }
    const handleReferral = () => {
        toast.success("Copied Referral Link")
        let link = window.location.origin + "/?ref=" + userAddress + "&lang=" + i18n.language
        navigator.clipboard.writeText(link);
        setShareLink(link)
    }
    const handleC = () => {
        setRefPop(false)
    }
    var params = "menubar=no,toolbar=no,status=no,width=570,height=570"; // for window
    const [shareLink, setShareLink] = useState("")
    function ShareToFaceBook() {
        let shareUrl = `http://www.facebook.com/sharer/sharer.phpu=${shareLink}`;
        window.open(shareUrl, "NewWindow", params);
    }
    function ShareToTwitter() {
        let Shareurl = `https://twitter.com/intent/tweet?url=${shareLink}&text=${shareLink}`;
        window.open(Shareurl, "NewWindow", params);
    }
    function ShareToTG() {
        let Shareurl = `https://telegram.me/share/url?url=${shareLink}&text=${shareLink}`;
        window.open(Shareurl, "NewWindow", params);
    }
    function ShareToInsta() {
        let Shareurl = `https://www.instagram.com/?url=${shareLink}`;
        window.open(Shareurl, "NewWindow", params);
    }
    function ShareToWA() {
        let Shareurl = `whatsapp://send?text=${shareLink}`;
        window.open(Shareurl, "NewWindow", params);
    }

    const [isOPen, setIsOPen] = useState(false)
    const [isOPenSpin, setIsOPenSpin] = useState(false)


    const shareUrl = "https://dreamcars.co";
    const title = "Dream Cars";

    const handleClick = () => {
        setIsOPen(!isOPen)
    }
    const handleClickSpin = () => {
        setIsOPenSpin(!isOPenSpin)
    }
    const handleClose = () => {
        setIsOPen(false)
    }
    const handleCloseSpin = () => {
        setIsOPenSpin(false)
    }

    const handelBonus = () => {
        setShow(!show)
    }

    return (
        <div id="hero" className="backgroundgr pt-[4rem] xs:pt-[4rem]">
            <div className=' space-y-4 py-[4rem] xs:py-[2.2rem] 2xl:w-[100%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[100%] w-[90%] max-w-[1276px] mx-auto ' >
                {
                    isOPen ? (
                        <div
                            id="authentication-modal"
                            className="flex overflow-y-auto overflow-x-hidden fixed top-[10%] right-0 left-0 z-[999] justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-[#00000080]"
                        >
                            <div className="relative  w-full max-w-xl max-h-full">
                                {/* <!-- Modal content --> */}
                                <div className="model relative shadow modelbgclr !overflow-hidden">
                                    {/* <!-- Modal header --> */}
                                    <div className="inner  bgmodelclr2 overflow-none">
                                        <div className="flex pb-[1.5rem] items-center justify-end pt-[1rem]  pr-5">
                                            <button
                                                type="button"
                                                className="end-2.5 text-[32px] text-[#1d1e24] bg-[#2e2f34] rounded-full"
                                                data-modal-hide="authentication-modal"
                                                onClick={handleClose}
                                            >
                                                <img className="h-[30px]" src={imgclose} alt="" />
                                                <span className="sr-only">Close modal</span>
                                            </button>
                                        </div>
                                        {/* <!-- Modal body --> */}
                                        <div className="pb-[2.5rem]  2xl:px-[30px] xl:px-[30px] lg:px-[30px] md:px-[30px] sm:px-[30px] px-[20px] relative">
                                            <form className="" action="#">
                                                <div>
                                                    <h2 className="text-[26px] text-white text-center leading-[28px] font-[700] pb-[24px]">Earn 5% Referral for $DCARS
                                                        referring your friends and community!</h2>
                                                    <p className="pb-[24px] text-[16px] text-white text-center font-[400] opacity-[0.9] ">Share your unique link below and receive</p>
                                                </div>
                                                <div className="flex flex-col gap-[24px]">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        className="text-[#1c1d23] text-[18px] font-[500] h-[54px] rounded-[17px] border-[1px] border-solid border-[#443567] pl-[20px] pr-[10px] bg-[#fff] opacity-[0.35] focus:ring-blue-500 focus:border-blue-500 block w-full dark:placeholder-gray-400 outline-none"
                                                        required
                                                    />


                                                    <div className="text-center">
                                                        <Button text={"Connect Wallet"} classes={"h-[50px] w-[200px] bgcolor text-[black] text-[18px] text-center font-[700] rounded-[30px]"} />
                                                    </div>
                                                    <p className="text-[16px] text-white text-center font-[400] opacity-[0.9] ">
                                                        Share it directly on your social media!
                                                    </p>
                                                    <div className="flex justify-center space-x-8">
                                                        {/* <img className="h-[30px] cursor-pointer" src={popic2} alt="" />
                                                    <img className="h-[30px] cursor-pointer" src={popic1} alt="" />
                                                    <img className="h-[30px] cursor-pointer" src={pop3} alt="" />
                                                    <img className="h-[30px] cursor-pointer" src={pop4} alt="" /> */}
                                                        <TwitterShareButton
                                                            url={shareUrl}
                                                            title={title}
                                                            className=""
                                                        >
                                                            <XIcon size={32} round />
                                                        </TwitterShareButton>
                                                        <FacebookShareButton
                                                            url={shareUrl}
                                                            className="Demo__some-network__share-button"
                                                        >
                                                            <FacebookIcon size={32} round />
                                                        </FacebookShareButton>
                                                        <TelegramShareButton
                                                            url={shareUrl}
                                                            title={title}
                                                            className="Demo__some-network__share-button"
                                                        >
                                                            <TelegramIcon size={32} round />
                                                        </TelegramShareButton>
                                                        <WhatsappShareButton
                                                            url={shareUrl}
                                                            title={title}
                                                            separator=":: "
                                                            className="Demo__some-network__share-button"
                                                        >
                                                            <WhatsappIcon size={32} round />
                                                        </WhatsappShareButton>
                                                    </div>
                                                </div>
                                            </form>
                                            <img src="" className="absolute bottom-[0] right-[0]" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : ("")
                }

                <div className='flex justify-between  lg:space-y-4 xs:space-y-3 sm:space-y-3 md:space-y-3 lg:block xs:block md:block sm:block'>

                    <div className="w-[50%] xl:w-[50%] flex flex-col justify-between lg:w-[100%]  xs:w-[100%] sm:w-[100%] md:w-[100%]  ">
                        <div className="space-y-[12px] pt-[1rem] xs:pt-[0rem]">
                            <h5 className="2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[16px] text-[12px] leading-[130%] font-[500]  text-white ">{t('ProductDetails.para1')}</h5>
                            <h3 className="text-[40px] xs:text-[24px] leading-[1.3]  font-[700] text-[#fff] "> {t('ProductDetails.title1')}</h3>
                            <h5 className="2xl:text-[20px] xl:text-[20px] lg:text-[20px] md:text-[20px] sm:text-[20px] text-[16px] font-[400] leading-[120%] text-white ">{t('ProductDetails.para2')}</h5>

                        </div>
                        <div className=" flex items-center py-[10px] justify-start space-x-[1rem]">
                            <p className="2xl:text-[15.871px]  xl:text-[15.871px] lg:text-[15.871px] md:text-[15.871px] sm:text-[15.871px]  text-[14px]  text-white underline font-[Lato]">{t('ProductDetails.para3last')}</p>
                            <img className="h-[40px] xs:h-[20px]" src={Solid} alt="" />
                        </div>
                        <div className=" xs:py-[0.8rem] space-y-[1rem] ">
                            <h3 className="2xl:text-[24px] xl:text-[24px] lg:text-[24px] md:text-[24px] sm:text-[24px] text-[16px] text-[#fff] font-[900] font-[700]">{t('ProductDetails.watchpara')}</h3>
                            <video controls preload="metadata" poster={poster} playsinline className="bg-filterved rounded-[20px] bgposter min-h-[430px] xs:min-h-[160px]  xs:h-[190px] sm:h-[330px] md:h-[420px] " height="100px" width="100%" >
                                <source src={""} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                    <div className='w-[45%] xl:w-[45%] lg:w-[65%] lg:mx-auto flex flex-col justify-end  xs:w-[100%] sm:w-[100%] md:w-[100%] z-[2]'>
                        <div className="backdrop-blur-md bg-[#d1d5db1f] border-r border-t rounded-[19px] space-y-[10px]">
                            <div className=' py-[30px]   2xl:px-[52px] xl:px-[45px] lg:px-[32px] md:px-[52px] sm:px-[52px] px-[10px]  space-y-4'>
                                <div>
                                    <h3 className="2xl:text-[36px] xl:text-[36px] lg:text-[26px] md:text-[26px] sm:text-[28px] text-[18px] font-[700] text-center  2xl:leading-[60px] xl:leading-[60px]  lg:leading-[35px] md:leading-[60px] sm:leading-[60px] text-white">{t('ProductDetails.card-title')}</h3>
                                </div>
                                <div className='bgposter '>

                                    <div className="bg-[#212121] max-w-[703px] rounded-t-[13px] px-8 xs:px-[15px]  sm:px-16 md:px-16 py-6 xs:pt-5 sm:pt-5 md:pt-8 ">
                                        <h3 className='2xl:text-[50px] xl:text-[44px] lg:text-[34px] md:text-[32px] sm:text-[36px] xs:text-[36px] font-[Poppins] font-[700] text-center text-[#fff]'>${totalContributions ? totalContributions.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "157,454.00"}</h3>
                                        <div className='flex items-center justify-between space-x-2 '>
                                            <h5 className=' text-center font-[Lato] 2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[13px] sm:text-[13px] xs:text-[9px]  text-[#929292]'>{
                                                percentage ? percentage.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 78.73
                                            }%  {t("of minimum goal raised")}</h5>
                                            <span className="relative">
                                                <button onMouseEnter={() => setPop1(true)} onMouseOut={() => setPop1(false)} type="button" className={`cursor-pointer `}>
                                                    <img src={Iicon} alt="" style={{ marginBottom: "-8px", width: "20px" }} />
                                                </button>
                                                <div className={`${pop1 ? 'absolute z-10 w-[220px] right-28 top-[0px] 2xl:right-[0px] xl:right-[0px] lg:right-[0px] 2xl:top-[-56px] xl:-top-[56px] lg:top-[-56px] md:right-[0px] md:top-[-56px] sm:right-[0px] sm:top-[-56px] xs:right-[0px] xs:top-[-56px] text-center py-2 px-3 bg-[#ffffff]  rounded-lg text-[#22222] text-sm' : 'hidden'}`}>
                                                    <span className="text-black font-[Lato]">The initial launch price for 1 $DCARS is set at $0.1.
                                                    </span>
                                                    <div className="absolute -z-99 w-5 h-5 -bottom-1 sm:right-[10px] xs:right-[10px] right-[10px] bg-[#ffffff] rotate-45"></div>
                                                </div>

                                            </span>
                                        </div>
                                        <div>
                                            <div className="z-1 w-full bg-gray-200 rounded-full h-2.5 ">
                                                <div className="bgcolor h-2.5 rounded-full w-[98%]" ></div>
                                            </div>
                                            <p className='text-end text-[12px]  text-[#929292]'>3,000,000</p>
                                        </div>
                                        <div>
                                            <h5 className='font-[Lato] text-center text-[18px] xs:text-[18px] font-[500] text-white'>{totalParticipants ? totalParticipants : "281"} {t("Participants")}</h5>
                                            <div>

                                            </div>
                                            <h5 className='font-[Lato] text-center text-[14px] xs:text-[14px]  font-[500] space-x-1 text-[#929292]'>{t('ProductDetails.card-listingPrice')}</h5>


                                        </div>
                                    </div>
                                    <div className='bg-[#3F3F3F] rounded-b-[10px] p-4 xs:pb-5 sm:pb-5 md:pb-8  xs:px-[15px] sm:px-14 md:px-10 '>
                                        <div className="flex justify-between">
                                        </div>
                                        <div className="py-2 space-y-5 xs:space-y-3" >
                                            <div className="flex space-x-2 items-center justify-center">
                                                <hr className="text-white w-[20%]" />
                                                <h4 className="text-white font-[Lato] text-[13px] xs:text-[9px]"> <span className="pr-1">1 DCARS</span>=<span>$0.0714</span></h4>
                                                <hr className="text-white w-[20%]" />
                                            </div>
                                            <div className="2xl:flex justify-between xl:flex lg:flex md:flex flex sm:flex  2xl:space-y-2 xl:space-y-2 lg:space-y-2 md:space-y-2 sm:space-y-0 space-y-0 2xl:space-x-0 xl:space-x-0 lg:space-x-0 md:space-x-0 sm:space-x-0 space-x-1">
                                                <button className="flex items-center xs:h-[40px] sm:h-[40px] xs:w-[100%] sm:w-[100%] md:w-[100%] lg:w-[100%] flex justify-center text-white xs:text-[14px] rounded-[14px] bg-[#ffffff17] space-y-3 2xl:px-10 xl:px-10 lg:px-10 md:px-10 sm:px-10 px-0  xs:py-1  py-[10px] backdrop-blur" onClick={() => handleTokenChange(1)}>
                                                    <div className="flex w-[60px]">
                                                        <img src={eth} className="h-[22px] mr-2" alt="" />
                                                        <span className="mt-0 contents font-[Lato] w-[30px] "> ETH</span>
                                                    </div>
                                                </button>
                                                <button className="flex items-center xs:h-[40px] sm:h-[40px] xs:w-[100%] sm:w-[100%] md:w-[100%] lg:w-[100%]  flex justify-center text-white xs:text-[14px] rounded-[14px] bg-[#ffffff17] space-y-3 2xl:px-10 xl:px-10 lg:px-10 md:px-10 sm:px-10 px-0  xs:py-1 py-[10px] backdrop-blur" onClick={() => handleTokenChange(2)}>
                                                    <div className="flex w-[60px]">
                                                        <img src={USDT} className="h-[22px] xs:h-[20px] mr-2" alt="" />
                                                        <span className="mt-0 contents font-[Lato] w-[30px] "> USDT</span>
                                                    </div>
                                                </button>
                                                <button className="flex items-center  xs:h-[40px] sm:h-[40px] xs:w-[100%] sm:w-[100%] md:w-[100%] lg:w-[100%]  flex justify-center text-white xs:text-[14px] rounded-[14px] bg-[#ffffff17] space-y-3 2xl:px-10 xl:px-10 lg:px-10 md:px-10 sm:px-10 px-0  xs:py-1 py-[10px] backdrop-blur" onClick={() => handleTokenChange(3)}>
                                                    <div className="flex w-[60px]">
                                                        <img src={BNB} className="h-[25px] mr-2" alt="" />
                                                        <span className="mt-0 contents font-[Lato] w-[30px] ">BNB</span>
                                                    </div>
                                                </button>

                                            </div>
                                            <div className=" bg-[#ffffff17] backdrop-blur rounded-[5px] p-3">

                                                <div className="flex">
                                                    <div className="w-[70%]">
                                                        <label htmlFor="" className="text-[#D0D0D0] font-[Lato] xs:text-[9px]">{t('ProductDetails.card-body-amount-pay')}</label>
                                                        <input type="text" className="text-[#fff] text-[20px] bg-[transparent] font-[Lato] w-[100%] outline-none rounded-l " placeholder="100" value={userAmount ? userAmount : ""} onChange={(e) => {
                                                            handleUserInput(e)
                                                        }} />
                                                    </div>
                                                    <button className="flex text-[#fff] text-[12px] font-[400] items-center justify-between p-[7px] !pr-[10px] w-[130px] h-[54px] border rounded-[40px] bg-[transparent]">
                                                        <div className="flex items-center space-x-[8px]">
                                                            <img src={tokenChoice === "ETH" ? eth : tokenChoice == "BNB" ? BNB : USDT} className="pr-2 w-[35px]" alt="" />
                                                            <span className="leading-[100%] pt-1">
                                                                <span>{tokenChoice === "ETH" ? "ETH" : tokenChoice == "BNB" ? "BNB" : "USDT"}</span> <br />
                                                                <span className="text-[8px] font-[400] text-[#7A7A7A]">ERC-20</span>
                                                            </span>
                                                        </div>

                                                    </button>
                                                </div>
                                            </div>
                                            <div className="space-y-1 bg-[#ffffff17] backdrop-blur rounded-[5px] p-3">
                                                <div className="flex">
                                                    <div className="w-[70%]">
                                                        <label htmlFor="" className="text-[#D0D0D0] font-[Lato] xs:text-[9px]">{t('ProductDetails.card-body-amount-receive')}
                                                            {" "}

                                                            <a href={"#membership"} className="">
                                                                <span className="text-[#00FA9A] font-bold underline">
                                                                    {userUSD / currentDcarsPrice >= 1000 && userUSD / currentDcarsPrice < 5000 ? "Mercedes NFT" :
                                                                        userUSD / currentDcarsPrice >= 5000 && userUSD / currentDcarsPrice < 10000 ? "Porsche NFT" :
                                                                            userUSD / currentDcarsPrice >= 10000 && userUSD / currentDcarsPrice < 25000 ? "Bentley NFT" :
                                                                                userUSD / currentDcarsPrice >= 21000 && userUSD / currentDcarsPrice < 100000 ? "Ferrari NFT" :
                                                                                    userUSD / currentDcarsPrice >= 100000 ? "Lamborghini NFT" : ""
                                                                    }
                                                                </span>
                                                            </a></label>
                                                        <input type="text" className="text-[#fff] text-[20px] bg-[transparent] font-[Lato] w-[100%] outline-none rounded-l " placeholder="100" value={userUSD ? userUSD.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ""} onChange={(e) => {
                                                            handleDcarsInput(e)
                                                        }} />
                                                    </div>
                                                    <button className="flex text-[#fff] text-[12px] font-[400] items-center p-2 space-x-[8px] w-[130px] h-[54px]  border rounded-[40px] bg-[transparent]">
                                                        <img src={Loinp} className=" w-[32px] xs:w-[35px] mr-2" alt="" />
                                                        $DCARS
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="w-[100%]">
                                                <ConnectButton.Custom>
                                                    {({
                                                        account,
                                                        chain,
                                                        authenticationStatus,
                                                        mounted,
                                                    }) => {
                                                        // Note: If your app doesn't use authentication, you
                                                        // can remove all 'authenticationStatus' checks
                                                        const ready = mounted && authenticationStatus !== 'loading';
                                                        const connected =
                                                            ready &&
                                                            account &&
                                                            chain &&
                                                            (!authenticationStatus ||
                                                                authenticationStatus === 'authenticated');
                                                        if (chain?.name)
                                                            setCurrentChain(chain?.name)
                                                        return (
                                                            <div
                                                                {...(!ready && {
                                                                    'aria-hidden': true,
                                                                    'style': {
                                                                        opacity: 0,
                                                                        pointerEvents: 'none',
                                                                        userSelect: 'none',
                                                                    },
                                                                })}
                                                            >
                                                                {(() => {
                                                                    if (!connected) {
                                                                        return (

                                                                            <button className='bgcolor font-[600] w-[100%] py-3 rounded xs:text-[14px]'
                                                                                onClick={openConnectModal} > {t('ProductDetails.card-body-walletBtn')}</button>
                                                                        );
                                                                    }

                                                                    if (chain.unsupported) {
                                                                        return (
                                                                            <button onClick={openChainModal} type="button">
                                                                                Wrong network
                                                                            </button>
                                                                        );
                                                                    }

                                                                    setUserAddress(account.address)
                                                                    return (
                                                                        <div>
                                                                            <button
                                                                                className="bgcolor uppercase w-[100%] py-3 rounded xs:text-[14px] xs:text-[14px] justify-center	 rounded-md flex items-center text-[15px] font-[600] font-[Poppins]"
                                                                                type="button" onClick={buyToken}>
                                                                                {buynowbtn}
                                                                            </button>
                                                                        </div>
                                                                    );
                                                                })()}
                                                            </div>
                                                        );
                                                    }}
                                                </ConnectButton.Custom>
                                            </div>
                                            {
                                                show &&
                                                <div className="flex ">
                                                    <input type="text" className="h-[40px] w-[100%] px-2 outline-none rounded-l " placeholder="Bonus code" />
                                                    <button className="flex h-[40px] items-center text-white rounded-r space-y-3 px-5  border-l bg-black">
                                                        <p className="text-[12px] text-whites font-[700]">{t('ProductDetails.card-body-Bonus-code-apply')}</p>
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                </div>
                                <div>
                                    <div className="flex justify-between  space-x-[15px] pt-[1rem] xs:pt-[0.5rem]">

                                        <a onClick={handleClickSpin} className="flex cursor-pointer border border-[#fff] text-[14px] xs:text-[11px] font-[500]  leading-[21px] flex w-[278px] h-[30px] items-center justify-center text-white font-[Lato] rounded-[10px] bg-[#3F3F3F]"> <img className="pr-1 w-[22px] h-[22px]" src={que} alt="" /> {t('ProductDetails.card-body-buyBtn')}</a>
                                        <button onClick={handleClick} className="cursor-pointer border border-[#fff] text-[14px] xs:text-[11px] font-[500] font-[Lato] leading-[21px] flex w-[278px] h-[30px] items-center justify-center text-white  rounded-[10px] bg-[#3F3F3F]"> <img className="pr-1 w-[22px] h-[22px]" src={refe} alt="" />{t('ProductDetails.card-body-referralBtn')}</button>


                                    </div>

                                    {
                                        !show &&
                                        <div className="pt-[2rem]">
                                            <p onClick={handelBonus} className="cursor-pointer text-[#DDD] underline text-center  text-[13px] font-[500] leading-[7px]">{t('ProductDetails.card-body-Bonus-code')}</p>
                                        </div>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default ProductDetailsSection;