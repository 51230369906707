import React, { useState, useEffect, useRef } from "react";

import Logo from "../../assects/logo/Logo.svg";

import walletblack from "../../assects/images/walletblack.png";
import fg1 from "../../assects/svgs/fg (1).svg";
import fg2 from "../../assects/svgs/fg (2).svg";
import fg3 from "../../assects/svgs/fg (3).svg";
import fg4 from "../../assects/svgs/fg (4).svg";
import fg5 from "../../assects/svgs/fg (5).svg";
import fg6 from "../../assects/svgs/fg (6).svg";
import fg7 from "../../assects/svgs/fg (7).svg";
import fg8 from "../../assects/svgs/fg (8).svg";
import fg9 from "../../assects/svgs/fg (9).svg";
import fg10 from "../../assects/svgs/fg (10).svg";
import fg11 from "../../assects/svgs/fg (11).svg";
import fg12 from "../../assects/svgs/fg (12).svg";
import fg13 from "../../assects/svgs/fg (13).svg";
import fg14 from "../../assects/svgs/fg (14).svg";
import WalletBlue from "../../assects/svgs/walletsvg.svg";

import i18n from "i18next";
import { useTranslation } from "react-i18next";
import HoverDropDn from "../../components/HoverDropDn";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import MyWallet from './MyWallet';

const Navbar = ({ handelClick }) => {
  const [lang, setLang] = useState("Eng");
  const [selectedLang, setSelectedLang] = useState({
    img: fg13,
    title: "English",
  });
  const [langStatus, setLangStatus] = useState(false);

  const [open, setOpen] = useState(false);
  const [navBarOpen, setNavBarOpen] = useState(false);
  const { t } = useTranslation();

  const locales = {
    en: { img: fg13, title: "English" },
    gr: { img: fg12, title: "German" },
    fr: { img: fg11, title: "French" },
    es: { img: fg10, title: "Spanish" },
    it: { img: fg9, title: "Italian" },
    du: { img: fg8, title: "Dutch" },
    tu: { img: fg7, title: "Turkey" },
    jp: { img: fg6, title: "Japan" },
    kr: { img: fg5, title: "Korean" },
    nr: { img: fg4, title: "Norwegian" },
    ru: { img: fg3, title: "Russian" },
    ch: { img: fg2, title: "Chinese" },
    pr: { img: fg1, title: "Portugese" },
    vt: { img: fg14, title: "Vietnamese" },
  };

  const handleLangDropdown = () => {
    setLangStatus(!langStatus);
  };
  const handleNavbarMenu = () => {
    setNavBarOpen(!navBarOpen);
  };
  const handleNavbarMenuClose = () => {
    setNavBarOpen(false);
  };

  const dropdownRef = useRef(null);
  const handleLanguageChange = (locale) => {
    i18n.changeLanguage(locale);

    // Set the selected language and close the dropdown
    setSelectedLang(locales[locale]);
    setLangStatus(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setLangStatus(false);
      // Add any other logic to close dropdowns here
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative bg-[#0D0904]">
      <nav className="2xl:w-[100%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[100%] w-[90%] max-w-[1300px] mx-auto py-1 xs:py-1">

        <header className="flex justify-between  2xl:px-[40px] xl:px-[40px] lg:px-[40px] md:px-[40px] mt-2">
          <div
            className="flex items-center space-x-10 h-16 max-w-screen-2xl sm:px-0 xs:px-0 lg:px- "
          >
            <div className="flex items-center">
              <button type="button" onClick={handleNavbarMenu} className="p-1 sm:mr-4 xl:hidden lg:hidden 2xl:hidden">
                <svg
                  className="w-6 h-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                    fill='white'
                  />
                </svg>
              </button>
              <div className="flex items-center w-[160px]">
                <a href="#hero"  >
                  <img src={Logo} className="h-[55px] xs:h-[52px] sm:h-[53px] w-[100%]" alt="Logo" />
                </a>
              </div>


            </div>
            <div className='flex items-center justify-between xs:justify-start space-x-5 xs:space-x-0 '>
              <div className='flex justify-between space-x-6 xs:hidden sm:hidden md:hidden '>
                <h4 className='text-white font-[Inter] text-[16px] lg:text-[10px] xl:text-[14px] font-[400] hover:text-[#a0a0a0]' > <a href="#scorpion">{t('main.navlink1')}</a> </h4>
                <h4 className='text-white font-[Inter] text-[16px] lg:text-[10px] xl:text-[14px] font-[400] hover:text-[#a0a0a0]'>  <a href="#howto">{t('main.navlink2')}</a></h4>
                <h4 className='text-white font-[Inter] text-[16px] lg:text-[10px] xl:text-[14px] font-[400] hover:text-[#a0a0a0]' > <a href="#whitepaper">{t('main.navlink3')}</a> </h4>
                <h4 className='text-white font-[Inter] text-[16px] lg:text-[10px] xl:text-[14px] font-[400] hover:text-[#a0a0a0]' > <a href="#token">{t('main.navlink4')}</a> </h4>
                <h4 className='text-white font-[Inter] text-[16px] lg:text-[10px] xl:text-[14px] font-[400] hover:text-[#a0a0a0]' > <a href="#success">{t('main.navlink5')}</a> </h4>
                <h4 className='text-white font-[Inter] text-[16px] lg:text-[10px] xl:text-[14px] font-[400] hover:text-[#a0a0a0]'> <a href="#faqs">{t('main.navlink6')}</a> </h4>

              </div>

              <li className='flex items-center xs:hidden sm:hidden md:hidden'>
                <div className='relative '>

                  <button onClick={handleLangDropdown} id="dropdownDefaultButton" data-dropdown-toggle="dropdown" class="text-white font-medium rounded-lg text-sm w-[full]  py-2.5 text-center inline-flex items-center px-2"
                    style={{
                    }} type="button" >
                    <img src={selectedLang.img} width={20} className="mr-2" />
                    {selectedLang.title}

                    <svg className={`fill-current text-white h-4 w-4 transform ${langStatus ? "rotate-180" : ""}`} aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>

                  {
                    langStatus &&
                    <>
                      <div className='relative'>


                        <div id="dropdown" ref={dropdownRef} className="absolute w-[1000px]   -left-[630%] top-[21px] z-10 ">
                          <HoverDropDn onLanguageChange={handleLanguageChange} locales={locales} />

                        </div>
                      </div>
                      <div onClick={handleLangDropdown} className="relative">
                        <div className="bg-[#f0f8ff00] !w-[1400px] -top-[2] right-[-513%]  absolute"></div>
                      </div>
                      {/* <div onClick={handleLangDropdown} className='relative'>
                                            <div className='bg-[#faebd700] !w-[1400px] -top-[2] right-[-513%] h-[100vh] absolute '></div>
                                        </div> */}
                    </>
                  }

                </div>

              </li>

            </div>
          </div>
          <div className='flex items-center '>

            <ConnectButton.Custom>
              {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted,
              }) => {
                // Note: If your app doesn't use authentication, you
                // can remove all 'authenticationStatus' checks
                const ready = mounted && authenticationStatus !== 'loading';
                const connected =
                  ready &&
                  account &&
                  chain &&
                  (!authenticationStatus ||
                    authenticationStatus === 'authenticated');

                return (
                  <div
                    {...(!ready && {
                      'aria-hidden': true,
                      'style': {
                        opacity: 0,
                        pointerEvents: 'none',
                        userSelect: 'none',
                      },
                    })}
                  >
                    {(() => {
                      if (!connected) {
                        return (
                          <button className="border border-[#fff] relative rounded-[30px] text-[#fff] 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[16px] text-[10px] font-[700] rounded-full 2xl:pr-[50px] xl:pr-[50px] lg:pr-[50px] md:pr-[50px] sm:pr-[40px] pr-[40px] pl-[20px] w-[auto] xs:h-[31px] h-[40px]"
                                                        onClick={openConnectModal} >
                            {" "}
                            {t("main.navBtn")}{" "}
                            <span className="absolute top-[0%] flex justify-center items-center right-[0%] w-[39px] xs:w-[30px] xs:h-[30px] inline-block h-[39px] bg-[#fff] rounded-[30px]">
                              <img src={walletblack} alt="" />
                            </span>{" "}
                          </button>

                        );
                      }

                      if (chain.unsupported) {
                        return (
                          <button className='bgcolor 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[16px] text-[12px] font-[Inter] font-[700] rounded-full px-4 h-[40px]' onClick={openChainModal}>
                            Switch Network
                          </button>
                        );
                      }

                      return (
                        <div>
                          <button className='px-3  rounded-full items-center justify-start w-[165px] xs:w-[150px] lg:w-[150px] h-[38px] flex items-center  p-1.5 text-[13px] xs:text-[12px] font-semibold font-[poppins] text-white space-x-1 relative bg-[#3f3f3f]' onClick={() => setOpen(!open)}>
                            <div className='absolute bg-white -right-[2.5px] h-[40px] w-[40px] rounded-full border border-[#3f3f3f] flex justify-center items-center'>
                              <span className='  '>
                                <img className='' src={WalletBlue} alt="" />
                              </span>
                            </div>
                            <span>
                              {/* {userWallet} */}
                              {account.address.slice(0, 6)}...{account.address.slice(-4)}
                            </span>

                          </button>
                          {
                            open ? <div className="z-10 absolute right-20 sm:-right-0 xs:-right-0 top-[110px] xs:top-[80px]  sm:w-full xs:w-full w-[23%] lg:w-[23%] rounded-[1rem] bg-[#000000] h-[440px] block "  >
                              <MyWallet accounts={account.address} />
                            </div> :
                              <></>
                          }
                        </div>
                      );
                    })()}
                  </div>
                );
              }}
            </ConnectButton.Custom>
          </div>
        </header >
        {
          navBarOpen &&
          <div className='' >

            <div className=' relative  space-y-8 py-12 px-6 2xl:hidden xl:hidden lg:hidden h-[1800px] bg-[#111010] w-full left-0 absolute'>

              <button className='text-white font-[Inter] text-[16px] font-[400] hover:text-[#a0a0a0]' onClick={handleNavbarMenuClose}  ><a href="#scorpion">{t('main.navlink1')}</a></button>
              <h4 className='text-white font-[Inter] text-[16px] font-[400] hover:text-[#a0a0a0]' onClick={handleNavbarMenuClose} ><a href="#howto">{t('main.navlink2')}</a></h4>
              <h4 className='text-white font-[Inter] text-[16px] font-[400] hover:text-[#a0a0a0]' onClick={handleNavbarMenuClose} > <a href="#whitepaper">{t('main.navlink3')}</a> </h4>
              <h4 className='text-white font-[Inter] text-[16px] font-[400] hover:text-[#a0a0a0]' onClick={handleNavbarMenuClose} > <a href="#token">{t('main.navlink4')}</a></h4>   <h4 className='text-white font-[Inter] text-[16px] font-[400] hover:text-[#a0a0a0]' onClick={handleNavbarMenuClose} >  <a href="#success">{t('main.navlink5')}</a></h4>
              <h4 className='text-white font-[Inter] text-[16px] font-[400] hover:text-[#a0a0a0]' onClick={handleNavbarMenuClose}><a href="#faqs">{t('main.navlink6')}</a></h4>
              <li className='flex items-center '>
                <div className='relative '>

                  <button onClick={handleLangDropdown} id="dropdownDefaultButton" data-dropdown-toggle="dropdown" class="text-white font-medium rounded-lg text-sm w-[full]  py-2.5 text-center inline-flex items-center "
                    style={{
                    }} type="button" >
                    <img src={selectedLang.img} width={20} className="mr-2" />
                    {selectedLang.title}
                    <svg className={`fill-current text-white h-4 w-4 transform ${langStatus ? "rotate-180" : ""}`} aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>

                  {
                    langStatus &&
                    <>
                      <div className='relative'>


                        <div id="dropdown" ref={dropdownRef} className="absolute xs:w-[200px] xs:mx-auto w-[1000px] x   xs:left-[10%] -left-[860%] top-[3px] z-10 ">
                          <HoverDropDn onLanguageChange={handleLanguageChange} locales={locales} />

                        </div>
                      </div>
                      <div onClick={handleLangDropdown} className="relative">
                        <div className="bg-[#f0f8ff00] w-[1400px]  -top-[2] right-[-513%]  absolute"></div>
                      </div>
                      {/* <div onClick={handleLangDropdown} className='relative'>
                                            <div className='bg-[#faebd700] !w-[1400px] -top-[2] right-[-513%] h-[100vh] absolute '></div>
                                        </div> */}
                    </>
                  }

                </div>

              </li>





            </div>
          </div>
        }

      </nav>
    </div>
  );
};
export default Navbar;