import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./i18n";

import '@rainbow-me/rainbowkit/styles.css';

import { mainnet, bsc } from 'wagmi/chains';

import { Theme } from '@rainbow-me/rainbowkit';
import {
  getDefaultConfig,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";

const myCustomTheme: Theme = {
  blurs: {
    modalOverlay: '...',
  },
  colors: {
    accentColor: 'linear-gradient(90deg, #AE8625 -3.12%, #F7EF8A 29.71%, #D2AC47 73.6%, #EDC967 102.14%)',
    modalBackground: '#fff',
  }
};

const projectId = 'f1fa989b52589ea5a9f742a48081a6f4';
const config = getDefaultConfig({
  appName: 'DreamCars',
  projectId: 'f1fa989b52589ea5a9f742a48081a6f4',
  chains: [mainnet, bsc],
  ssr: true, 
});
const queryClient = new QueryClient();
  
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={

      <>
        <div className="bg-[#111010] ">
          <div className="h-[100vh] bg-[#0e0e0ed9] w-[100%] flex h-[100vh] space-y-2  justify-center items-center">
            <div className="mx-auto">
              <h3 className="ml-[10px] text-[36px] gradientText font-[800]">LOADING</h3>
            </div>
          </div>
        </div>

      </>
    }>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider modalSize="compact" theme={myCustomTheme}>
          <App />

        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
    </Suspense >
  </React.StrictMode>
);
reportWebVitals();
